import {createSlice} from '@reduxjs/toolkit';

export const menuSlice = createSlice({
    name: 'toggleMenu',
    initialState: {
        value: false
    },
    reducers: {
        toggle: (state, action) => {state.value = !action.payload ? !state.value : action.payload},
    }
})

export const {toggle} = menuSlice.actions;
export default menuSlice.reducer;
import React from 'react'
import '../line_menu/lineMenu.css'
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoCloseOutline } from 'react-icons/io5';
import { MdLogin } from 'react-icons/md';
import { BiPhoneCall, BiMailSend } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toggle } from '../../store/menuSlice';
import { useEffect, useState } from 'react';

export default function LineMenu() {

  const [menuComp, setMenuComp] = useState(<GiHamburgerMenu />)
  const menu = useSelector(state => state.toggleMenu.value);
  const dispatch = useDispatch();


  useEffect(() => {
    if (window.innerWidth < 751) {
      if (!menu) setMenuComp(<GiHamburgerMenu />);
      else setMenuComp(<IoCloseOutline />);
    }
  }, [menu])

  const closeOpen = () => {
    if (window.innerWidth < 751) {
      dispatch(toggle())
    }
  }

  return (
    <div className="menu">
      <div className="container">
        <div className="menu__title">
          <div className="menu_burger" onClick={closeOpen}>
            {menuComp}
            <div className='menu_burger_text'>Меню</div>
          </div>
          <div className="menu_list">
            <div className="menu_list_item"><BiPhoneCall />+7(996)567-15-50</div>
            <div className="menu_list_item"><BiMailSend /> info@uk-vertex.ru</div>
            <Link to={'/auth/login'} className="menu_list_item login_btn"><MdLogin /></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

import {createSlice} from '@reduxjs/toolkit';

export const fileSlice = createSlice({
    name: 'fileAdd',
    initialState: {
        add: false, 
        del: false
    },
    reducers: {
        toggleFileAdd: (state) =>{state.add = !state.add},
        toggleFileDel: (state) =>{state.del = !state.del}
    }
})

export const {toggleFileAdd, toggleFileDel } = fileSlice.actions;
export default fileSlice.reducer;
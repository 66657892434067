import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import FormModal from '../FormModal';
import './indexPage.css';
import { useState, useEffect } from 'react';
//import { downloadFile } from '../../../../actions/file';
import {ImCross} from 'react-icons/im';
import Alert from '../../../modal/Alert';
import { deleteFIle } from '../../../../actions/file';
import {toggleFileDel} from '../../../../store/fileSlice'

export default function IndexPage() {
    const isAdmin = useSelector(state => state.checkUserData.roles);
    const uploadAction = useSelector(state => state.fileAdd.add);
    const deleteAction = useSelector(state => state.fileAdd.del);
    const [files, setFiles] = useState(null);
    const dispatch = useDispatch();

/*     const getFiles = async () =>{
        const res = await fetch('api/documents')
        const docs = await res.json();
        setFiles(docs);
    }; */

/*     const deleteDocument = async (id, token)=>{
        await deleteFIle(id, token);
        dispatch(toggleFileDel());
    } */

/*     useEffect(()=>{
        getFiles();
    }, [uploadAction, deleteAction, isAdmin]) */

    const pageFiles = files?.map(({ id, filelink, fileTitle }) => {
            return (
             <div key={id} className="page_files">
                <div className="files_title">
                    <div className="files_title_text">{fileTitle}</div>
                </div>
                <div className="files_available">
                    <button className="btn_download"
                            /* onClick={()=>downloadFile(id, filelink)} */
                            >Скачать </button>
                            <span style={{fontSize: '0.6rem'}}>{filelink}</span>
                </div>
                {isAdmin.includes('admin') ? 
                <Alert modalButton={ <ImCross className='delete_file' /> }
                       modalTitle={'Удаление документа'} 
                       modalMessage={`Фаил "${fileTitle}" будет безвозвратно удален! Вы уверены?`}
                       /* action={deleteDocument} */
                       id={id} 
                       />:
                       null}
            </div>
            ) 
        })
    
    return (
        <div>
            <div className="page_titile">
                <h1>Управляющая компания</h1>
                <h2>Общество с ограниченной ответственностью</h2>
                <h2>"УК Вэртекс"</h2>
            </div>
            <div className="page_subtitle">
                <h2>Здравствуйте, уважаемые жильцы!</h2>
                <h2>Рады приветствовать вас на сайте нашей управляющей компании!</h2>
                <p>Здесь вы найдёте всю необходимую информацию, подлежащую раскрытию, согласно Стандарта раскрытия информации (Постановления Правительства №731 от 23 сентября 2010).
                </p>
            </div>
            <div className="documents">
                {pageFiles ? pageFiles : <div style={{ textAlign: 'center' }} >Нет документов </div>}
                {isAdmin.includes('admin') ? 
                <div className='btn-modal'><FormModal /></div> :
                null}
            </div>
        </div>
    )
}

import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useRef } from 'react';
import './formModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { toggleFileAdd } from '../../../store/fileSlice';

function FormModal(props) {
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);
  const [closeAll, setCloseAll] = useState(false);
  const [fileTitle, setFileTitle] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const filePicker = useRef();
  const token = useSelector(state =>state.checkUserData.accessToken);
  const dispatch = useDispatch();

  const toggle = () => setModal(!modal);
  const toggleNested = () => {
    setNestedModal(!nestedModal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setNestedModal(!nestedModal);
    setCloseAll(true);
  };

  const handlePick = () => {
    filePicker.current.click();
  }
  const handleUpload = async () => {
    if(!selectedFile){
      return alert('Фаил не выбран!');
    }
    if(!fileTitle){
      return alert('Введите имя файла!');
    }
    if(selectedFile.size > 5000000){
      return alert('Размер файла не должен превышать 5 Мб')
    }
    const formData = new FormData();
    formData.append('file', selectedFile);
    formData.append('fileTitle', fileTitle);

    await fetch('api/upload', {
      method: 'POST',
      headers: {'authorization':`Bearer ${token}` },
      body: formData
    });
    toggleAll();
    setSelectedFile(null)
    dispatch(toggleFileAdd());
  }
  return (
    <div>
      <Button color="info" style={{color: '#fff'}} onClick={toggle}>
        Добавить
      </Button>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Добавление нового документа</ModalHeader>
        <ModalBody>

          <div className='file_data'>
              <input className='file_name'
                     type="text" 
                     placeholder='Название документа' 
                     onChange={(e) => setFileTitle(e.target.value)}
                     maxLength='100'/>
              <input className='file_input hidden'
                     type="file" 
                     ref={filePicker}
                     accept='.doc, .pdf, .docx'
                     onChange={(e) => setSelectedFile(e.target.files[0])}/>
              <Button color="info"
                      outline
                      onClick={handlePick}
                      >Добавить фаил</Button>
                      <span> {selectedFile?.name} </span>    
          </div>
          <br />
          <Modal
            isOpen={nestedModal}
            toggle={toggleNested}
            onClosed={closeAll ? toggle : undefined}
          >
            <ModalHeader>Создание новой записи</ModalHeader>
            <ModalBody>Добавить документ на страницу?</ModalBody>
            <ModalFooter>
              <Button color="info" onClick={handleUpload}>
                Ok
              </Button>{' '}
              <Button color="danger" outline onClick={toggleNested}>
                Отмена
              </Button>
            </ModalFooter>
          </Modal>
        </ModalBody>
        <ModalFooter>
          <Button color="info" style={{color: '#fff'}} onClick={toggleNested}>
          Добавить
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default FormModal;
import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';

function Alert({modalTitle, modalMessage, modalButton, action, id}) {
  const [modal, setModal] = useState(false);
  const token = useSelector(state =>state.checkUserData.accessToken);

  const toggle = () => setModal(!modal);

  const currentAction = (id)=>{
    action(id,token);
    toggle();
  }
  return (
    <div>
        <div onClick={toggle}>{modalButton}</div>
      
        <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader><b>{modalTitle}</b></ModalHeader>
        <ModalBody>
          {modalMessage}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={()=>currentAction(id)}>
            Ok
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Alert;
import React from 'react'
import image from '../images/header_img.png'
import '../header/header.css'

export default function header() {
    return (
        <header>
            <div className="container">
                <div className="header_inner">
                    <div className="header_img">
                        <img src={image} alt=""/>
                    </div>
                </div>
                </div>
        </header>
    )
}

import React from 'react';
import './authorization.css'
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from './../../store/authSlice';
import { useState} from 'react';

export function Authorization() {
    const isAuth = useSelector(state => state.checkUserData.authorized);
    const isAdmin = useSelector(state => state.checkUserData.roles);
    const userData = useSelector(state =>state.checkUserData.userData);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const setAuth = () => {
        dispatch(login({ email, password }));
    }
    const userLogout = () => {
        dispatch(logout());
    }
 
    if (isAuth) {
        return (
            <div className='authBlock'>
                <h2>Вход выполнен: {`${userData?.user?.email} ${isAdmin.includes('admin') ? '(администратор)' : ''}`}</h2>
                <button className="btn_enter" type='button' onClick={userLogout}> Выход </button>
            </div>
        )
    }

    return (
        <div className='authBlock'>
            <h2>Вход для администратора:</h2>
            <form className='authForm'>
                <input type="text"
                    value={email}
                    placeholder='Логин'
                    onChange={e => setEmail(e.target.value)}
                />
                <input type="password"
                    value={password}
                    placeholder='Пароль'
                    onChange={e => setPassword(e.target.value)}
                />
                <button className="btn_enter" type="button" onClick={setAuth}>Вход</button>
            </form>
            <h3 style={{'color': '#ab0606'}}>Регистрация новых пользователей в данный момент недоступна</h3>
        </div>

    )
}

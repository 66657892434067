import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const login = createAsyncThunk(
    'auth/login',
    async function(userData, {dispatch}){
        try {
            const res = await fetch('/api/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(userData)
            });
            if(!res.ok){
                throw new Error('Данные не получены');
            }
            const data = await res.json();
            dispatch(isAuth(true));
            dispatch(userRole(data.user.roles));
            dispatch(accessToken(data.accessToken))
            localStorage.setItem('userInfo',JSON.stringify(data.user));
            //localStorage.setItem('token', data.accessToken);
            return data;
        } catch (e) {
           return console.log(e);
        }
    }
)
export const logout = createAsyncThunk(
    'auth/logout',
    async function(_, {dispatch}){
        try {
            const res = await fetch('/api/logout', {method: 'POST'});
            if(!res.ok){
                throw new Error('Что то пошло не так');
            }
            dispatch(accessToken(''));
            //localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            dispatch(isAuth(false))
            dispatch(userRole(['guest']))
        } catch (e) {
            return console.log(e);
        }
    }
) 
export const checkUserAuth = createAsyncThunk(
    'auth/refresh',
    async function (_, {dispatch}){
        try {
            const res = await fetch('/api/refresh');
            if(!res.ok){
                return
                //throw new Error('Пользователь не авторизован')
            }
            const data = await res.json();
            dispatch(isAuth(true));
            dispatch(userRole(data.user.roles));
            dispatch(accessToken(data.accessToken));
            dispatch(userInfo(data.user));
            localStorage.setItem('userInfo',JSON.stringify(data.user));
            //localStorage.setItem('token', data.accessToken)
            return data;
        } catch (e) {
            return console.log(e);
        }
    }

)

export const authSlice = createSlice({
    name: 'checkUserData',
    initialState: {
        authorized: false,
        status: null,
        error: null,
        userData: null,
        roles: ['guest'],
        accessToken: '',
        userInfo: 'userInfo'
    },
    reducers: {
        isAuth: (state, action) => {state.authorized = action.payload},
        userRole: (state, action) =>{state.roles = action.payload},
        accessToken: (state, action)=>{state.accessToken = action.payload},
        userInfo: (state, action)=>{state.userInfo = action.payload}
    },
    extraReducers(builder) {
        builder
        .addCase(login.pending, (state) => {
            state.status = 'loading';
            state.error = null;
         })
        .addCase(login.fulfilled, (state, action)=>{
            state.status = 'resolved';
            state.userData = action.payload;
        })
        .addCase(login.rejected, (state, action)=>{
            state.status = 'rejected';
            state.error = action.payload;
        })
        .addCase(logout.fulfilled, (state)=>{
            state.status = 'resolved';
            state.userData = null;
        })
        .addCase(checkUserAuth.fulfilled, (state, action)=>{
            state.userData = action.payload; 
        })
    }
})

export const {isAuth, userRole,accessToken, userInfo} = authSlice.actions;
export default authSlice.reducer;
import React from 'react'
import AsideMenu from '../aside_menu/AsideMenu';
import '../all_content/layout.css'
import { Outlet } from 'react-router-dom';
import LineMenu from '../../line_menu/LineMenu'
import Header from '../../header/Header'
import Footer from '../../footer/Footer'

export default function Layout() {

  return (
    <div className='wrapper'>
      <Header />
      <section className='content'>
        <LineMenu />
        <div className="container">
          <div className="all_content">
            <AsideMenu />
            <main className='content_main'>
              <Outlet />
            </main>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  )
}

import React from 'react'
import './houses.css'

export default function InControl() {
  return (
    <>
      <h2 className='noContent'>Дома в управлении</h2>
      <ul className='incontrol__list'>
        <li>город Нижний Новгород, улица Деловая, 24/2;</li>
        <li>город Нижний Новгород, улица Березовская, 96/1;</li>
        <li>город Нижний Новгород, улица Моховая, 11.</li>
      </ul>
    </>
  )
}

import {configureStore} from '@reduxjs/toolkit';
import authReducer from './authSlice';
import menuReducer from './menuSlice';
import fileReducer from './fileSlice'

export default configureStore({
    reducer:{
        checkUserData: authReducer,
        toggleMenu: menuReducer,
        fileAdd: fileReducer
    }
})